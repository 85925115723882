<template>
  <section>
    <div class="container-fluid">
      <!--Breadcrumb-->
      <nav
        style="
          --bs-breadcrumb-divider: url(
            &#34;data:image/svg + xml,
            %3Csvgxmlns='http://www.w3.org/2000/svg'width='8'height='8'%3E%3Cpathd='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z'fill='currentColor'/%3E%3C/svg%3E&#34;
          );
        "
        aria-label="breadcrumb"
      >
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'servicos' }" tag="a">
              <i class="fas fa-home"></i> Home
            </router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Assina:Inicio' }" tag="a">
              USP Assina
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Cadastrar gestor
          </li>
        </ol>
      </nav>
      
      <uspCartao titulo="Cadastrar gestor">
        <template slot="corpo">
          <form class="form-row" @submit.prevent="buscar">
            <div class="form-group col-12 col-xl-2">
                <label for="buscaCodpes" class="control-label">Número USP</label>
                <input
                type="text"
                v-model="buscaCodpes"
                class="form-control"
                id="buscaCodpes"
                />
            </div>
            <div class="form-group col-12 col-xl-4">
                <label for="buscaNompes" class="control-label">Nome</label>
                <input
                type="text"
                v-model="buscaNompes"
                class="form-control"
                id="buscaNompes"
                />
            </div>
            <div class="form-group col-12 col-xl-2 d-flex align-items-end">
                <button
                type="submit"
                class="btn btn-primary btn-block"
                :disabled="buscando"
                >
                <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    v-if="buscando"
                ></span>
                Buscar
                </button>
            </div>
          </form>
          <!-- Resultados da pesquisa -->
          <AssinaResultados
            :lista="resultadosBusca"
            v-if="temResultados"
            titulo="Resultados da busca"
          >
            <template slot-scope="conteudo">
              <div class="conteudo">
                <div class="multiplas-linhas">
                  <span class="titulo"> {{ conteudo.item.nome }}</span>
                  <span class="subtitulo"
                    >Número USP: {{ conteudo.item.codpes }}
                  </span>
                </div>
              </div>
              <div class="acoes ml-3">
                <span class="subtitulo">
                  {{ papeis[conteudo.item.numnivseg == 2 && conteudo.item.temporario == 'S' ? 0 : conteudo.item.numnivseg] || "Não é usuário" }}
                </span>
              </div>
              <div class="acoes ml-3">
                <button
                  type="button"
                  class="btn btn-sm btn-success"
                  :class="conteudo.item.numnivseg == 1 || (conteudo.item.numnivseg == 2 && conteudo.item.temporario == 'N') ? 'invisible' : ''"
                  @click="cadastrar(conteudo.item)"
                  :disabled="processando || conteudo.item.numnivseg == 1 || (conteudo.item.numnivseg == 2 && conteudo.item.temporario == 'N')"
                >
                  <i
                    class="fas fa-sm fa-plus"
                    v-if="!processando"
                  >
                  </i>
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    v-if="processando"
                  >
                  </span>
                  Cadastrar como gestor
                </button>
              </div>
            </template>
          </AssinaResultados>
        </template>
      </uspCartao>
    </div>
  </section>
</template>

<script>
import { PortalLogger as Log } from '@/utils/';
import AssinaResultados from '../components/AssinaResultados';
import LoteDocumentoServico from '../dominio/loteDocumento/LoteDocumentoServico.js';

const api = LoteDocumentoServico.build({});

export default {
  name: 'CadastrarGestor',

  components: {
    AssinaResultados
  },

  props: {
    titulo: {
      type: String,
      default: '',
    },
    modoEdicao: {
      type: Boolean,
      default: false,
    },
    codlotdocasi: {
      type: String,
      default: "",
    },
    tipasilotdoc: {
      type: String,
      default: "",
    },
    sitlotdocasi: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      buscaCodpes: "",
      buscaNompes: "",
      buscaCodema: "",
      buscando: false,
      resultadosBusca: [],
      erro: "",
      processando: false
    };
  },

  computed: {
    temResultados() {
      return this.resultadosBusca.length > 0;
    },
  },

  watch: {
    selecionados(newValue) {
      Log.info("Atualizacao selecionados!");
      this.$emit(
          "uspPessoaAtualizouLista",
          ((newValue || []).length && newValue.some(elem => elem.tipptpasidoc == "A"))
      );
    },
    buscaCodpes() {
      if (this.buscaCodpes) this.limparCamposBusca("buscaCodpes");
    },
    buscaNompes() {
      if (this.buscaNompes) this.limparCamposBusca("buscaNompes");
    },
    buscaCodema() {
      if (this.buscaCodema) this.limparCamposBusca("buscaCodema");
    },
    erro(newValue) {
      if (newValue) {
        this.$toast.error(this.erro);
        this.erro = "";
      }
    },
  },

  created() {
    this.papeis = {
      "0": "Gestor temporário",
      "1": "GRS",
      "2": "Gestor",
      "3": "Assinante"
    };
  },

  methods: {
    limparCamposBusca(menosEste) {
      const self = this;
      ["buscaCodpes", "buscaNompes", "buscaCodema"]
        .filter((elem) => elem != menosEste)
        .forEach((elem) => (self[elem] = ""));
    },

    buscar() {
      const self = this;
      self.resultadosBusca = [];
      self.buscando = true;
      api
        .buscarUsuario({
          codpes: self.buscaCodpes,
          nompes: self.buscaNompes,
          codema: self.buscaCodema,
        })
        .then((resp) => {
          self.resultadosBusca = resp;
          if (resp.length == 0) {
            self.erro = "Usuário não encontrado!";
          }
        })
        .catch(erro => {
          self.erro = (erro || "").mensagem || erro;
        })
        .finally(() => {
          self.buscando = false;
        });
    },

    cadastrar(pessoa) {
      const self = this;
      self.processando = true;
      api
        .cadastrarGestor(pessoa)
        .then((resp) => {
          pessoa.numnivseg = (resp || {}).numnivsegusu || 2;
          self.buscar();
        })
        .catch((erro) => (self.erro = (erro || "").mensagem || erro))
        .finally(() => (self.processando = false));
    },
  },
};
</script>