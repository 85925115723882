<template>
  <uspCartao titulo="Arquivos">
    <template #corpo>
      <div class="example-simple">
        <h6
          v-if="!!files.length"
          class="subtitulo"
        >
          Arquivos já enviados
        </h6>
        <div
          v-if="tipasilotdoc == 'I'"
          class="alert alert-light"
          role="alert"
        >
          Para este tipo de assinatura, &eacute; permitido somente um documento por lote. Cada documento pode ter, no m&aacute;ximo, 10 Mb.<br>
          Para assinar documentos pela Imprensa Oficial, &eacute; necess&aacute;rio ter este plugin instalado: <a
            target="_blank"
            href="https://get.websignerplugin.com/"
          >https://get.websignerplugin.com/</a><br>
          O documento com o comprovante da(s) assinatura(s) eletrônica(s) / digital(is) anexada(s) só estará disponível para ser visualizado abaixo após todos os participantes terem efetuado suas respectivas assinaturas.<br>
          Antes disso, a visualização do arquivo mostrará somente o arquivo original encaminhado para assinatura.
        </div>
        <div
          v-if="tipasilotdoc == 'A'"
          class="alert alert-light"
          role="alert"
        >
          Para este tipo de assinatura, &eacute; permitido somente um documento por lote. O documento pode ter, no m&aacute;ximo, 10 Mb. <br>
          O documento com o comprovante da(s) assinatura(s) eletrônica(s) / digital(is) anexada(s) só estará disponível para ser visualizado abaixo após todos os participantes terem efetuado suas respectivas assinaturas.<br>
          Antes disso, a visualização do arquivo mostrará somente o arquivo original encaminhado para assinatura.
        </div>
        <div
          v-if="tipasilotdoc == 'D'"
          class="alert alert-light"
          role="alert"
        >
          Cada documento pode ter, no m&aacute;ximo, 10 Mb. <br>
          A soma dos documentos n&atilde;o pode exceder os 18 Mb. <br>
          O documento com o comprovante da(s) assinatura(s) eletrônica(s) / digital(is) anexada(s) só estará disponível para ser visualizado abaixo após todos os participantes terem efetuado suas respectivas assinaturas.<br>
          Antes disso, a visualização do arquivo mostrará somente o arquivo original encaminhado para assinatura.
        </div>
        <div
          v-if="tipasilotdoc == 'U'"
          class="alert alert-light"
          role="alert"
        >
          Cada documento pode ter, no m&aacute;ximo, 10 Mb. <br>
          O documento com o comprovante da(s) assinatura(s) eletrônica(s) / digital(is) anexada(s) só estará disponível para ser visualizado abaixo após todos os participantes terem efetuado suas respectivas assinaturas.<br>
          Antes disso, a visualização do arquivo mostrará somente o arquivo original encaminhado para assinatura.
        </div>
        <div class="upload usp-resultados destaque">
          <ul class="list-group">
            <li
              v-show="tipasilotdoc == 'U' && files.filter(a => a.link).length > 0"
              class="list-group-item"
            >
              <div class="linha float-right">
                <div class="form-check">
                  <input
                    v-model="mostrarSomenteAssinar"
                    class="form-check-input"
                    type="checkbox"
                    value=""
                  >
                  <label class="form-check-label">
                    Mostrar somente documentos pendentes para assinar
                  </label>
                </div>
              </div>
            </li>
            <li
              v-for="file in files"
              v-show="!mostrarSomenteAssinar || file.link"
              :key="file.id"
              class="list-group-item"
            >
              <div class="linha">
                <div class="conteudo d-flex flex-row">
                  <img
                    v-if="file.thumb"
                    :src="file.thumb"
                    width="150"
                    height="auto"
                    class="mr-5 img-fluid"
                  >
                  <div class="info d-flex flex-column">
                    <span>{{ file.name || "Arquivo sem nome" }}</span>
                    <span v-if="file.size">{{ file.size }} bytes</span>
                    <span v-if="file.error">{{ file.error }}</span>
                    <span v-else-if="file.success">Gravado</span>
                    <span v-else-if="file.active">Enviando</span>
                    <span v-else />
                  </div>
                </div>
                <div class="acoes">
                  <button
                    v-if="modoEdicao && file.coddocuspasi"
                    type="button"
                    class="btn btn-sm btn-danger"
                    :disabled="carregando" 
                    @click.prevent="removerArquivo(file)"
                  >
                    <i class="fas fa-trash" /> Excluir
                  </button>
                  <a
                    v-if="file.link"
                    role="button"
                    :href="file.link"
                    target="_blank"
                    class="btn btn-sm btn-primary" 
                    :class="{disabled: carregando}" 
                    @click="abrirLinkTeste($event, file.link)"
                  >
                    <i class="fas fa-signature" /> {{ tipasilotdoc == 'I' && file.eh_assinante == 'N' ? 'Acompanhar' : 'Assinar' }}
                  </a>
                  <button
                    v-if="file.success"
                    type="button"
                    class="btn btn-sm btn-info"
                    :disabled="carregando"
                    @click.prevent="visualizarArquivo(file)"
                  >
                    <i class="fas fa-eye" /> Visualizar
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div v-show="files.length == 0 || (tipasilotdoc != 'A' && tipasilotdoc != 'I')">
          <div :class="{ 'example-btn': true, 'd-none': !modoEdicao }">
            <file-upload
              ref="upload"
              v-model="files"
              class="btn btn-primary mt-3"
              :custom-action="enviarArquivo"
              post-action="/upload/post"
              extensions="pdf,gif,jpg,jpeg,png,webp"
              accept="application/pdf"
              :multiple="true"
              :size="1024 * 1024 * 20"
              @input-filter="inputFilter"
              @input-file="inputFile"
            >
              <i class="fa fa-folder-open" />
              Selecionar arquivos
            </file-upload>
          </div>
        </div>
        <uspModal
          ref="modal"
          titulo="Visualizar arquivo"
          max-width="90%"
          max-height="80%" 
          @fechar="revokeDocfileUrl"
        >
          <template #body>
            <div style="width: 80vw; height: 70vh;">
              <object
                :data="docFile"
                v-bind="contentTypeAttr"
                width="100%"
                height="100%"
              >
                <p>Não foi possível exibir o documento, tente fazer download e ver o documento em outro aplicativo.</p>
                <a
                  :href="docFile"
                  download="documento.pdf"
                  class="btn btn-sm btn-primary"
                >
                  Download
                </a>
              </object>
            </div>
          </template>
        </uspModal>
        <!--<div class="pt-5">
      Source code:
      <a
        href="https://github.com/lian-yue/vue-upload-component/blob/master/docs/views/examples/Simple.vue"
        >/docs/views/examples/Simple.vue</a
      >
    </div>-->
      </div>
    </template>
  </uspCartao>
</template>
<script>
import { PortalLogger as Log } from '@/utils/';
import FileUpload from 'vue-upload-component';
import LoteDocumentoServico from '../dominio/loteDocumento/LoteDocumentoServico.js';

const api = LoteDocumentoServico.build({});

export default {
  components: {
    FileUpload
  },
  props: {
    codlotdocasi: {
      type: String,
      default: '',
    },
    modoEdicao:{
      type: Boolean,
      default: false
    },
    tipasilotdoc: {
      type: String,
      default: "",
    },
    sitlotdocasi: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      mostrarSomenteAssinar: false,
      files: [],
      docFile: {},
      docType: '',
      carregando: false,
      erro: '',
      contentTypeAttr: /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ? {} : {type: "application/pdf"}
    };
  },
  computed: {
    temPendencia: function() {
      return this.files.length > 0 && this.files.some(elem => !elem.success && !elem.active);
    }
  },
  watch: {
      codlotdocasi(newValue, oldValue) {
          if (newValue != oldValue) {
            this.carregarListaArquivos();
          }
      },
      files() {
        if (!this.$refs.upload.active && this.files.some(elem => !elem.success && !elem.active && !elem.error)) {
          this.$refs.upload.active = true;
        }
        if (this.$refs.upload.active) {
          this.$emit("AssinaArquivosAtualizouLista", false);
        } else {
          this.$emit(
            "AssinaArquivosAtualizouLista",
            !!((this.files || []).length) && this.files.every(elem => elem.success)
          );
        }
      },
      erro(newValue) {
      if (newValue) {
        this.$toast.error(this.erro);
        this.erro = "";
      }
    },
  },
  mounted() {
    this.carregarListaArquivos();
  },
  methods: {
    inputFilter(newFile, oldFile, prevent) {
      Log.info('Input Filter');
      if (newFile && !oldFile) {
        // Before adding a file
        // Filter system files or hide files
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }
        // Filter php html js file
        if (!/\.pdf$/i.test(newFile.name)) {
          return prevent();
        }
      }
    },
    inputFile(newFile, oldFile) {
      Log.info('Input File');
      if (newFile && !oldFile) {
        // add
        Log.info("add", newFile);
      }
      if (newFile && oldFile) {
        // update
        Log.info("update", newFile);
      }
      if (!newFile && oldFile) {
        // remove
        Log.info("remove", oldFile);
      }

      /*if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        // Create a blob field
        newFile.blob = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }
        // Thumbnails
        newFile.thumb = "";
        if (newFile.blob && newFile.type.substr(0, 6) === "image/") {
          newFile.thumb = newFile.blob;
        }
      }*/
    },
    enviarArquivo(file) {
      const self = this;
      self.carregando = true;
      /*return new Promise(function(resolve) {
        setTimeout(resolve, 3000);
      });*/
      return api.registrarDocumento(file.file, {codlotdocasi: this.codlotdocasi})
        .then((resp) => {
          Log.info(resp);
          file.codlotdocasi = self.codlotdocasi;
          file.coddocuspasi = resp.coddocuspasi;
          file.size = undefined;
        })
        .catch(erro => self.erro = (erro || "").mensagem || erro)
        .finally(() => {
          self.carregando = false;
        });
    },
    carregarListaArquivos() {
      const self = this;
      self.carregando = true;
      return api.listarDocumentos({codlotdocasi: this.codlotdocasi})
        .then((resp) => {
            if ((resp || []).length > 0) {
              self.$refs.upload.clear();
              self.$refs.upload.add(
                resp.map(elem => ({ ...elem, name: elem.nomarq_fs,
                  active: true,
                  success: true}))
              );
            }
        })
        .catch(erro => self.erro = (erro || "").mensagem || erro)
        .finally(() => {
            self.carregando = false;
        });
    },
    removerArquivo(file) {
      const self = this,
            bean = {
              codlotdocasi: file.codlotdocasi,
              coddocuspasi: file.coddocuspasi
            };
      self.carregando = true;
      if (file.success) {
        return api.removerDocumento(bean)
          .then(() => self.$refs.upload.remove(file))
          .catch(erro => self.erro = (erro || "").mensagem || erro)
          .finally(() => {
              self.carregando = false;
          });
      } else {
        self.$refs.upload.remove(file);
      }
    },
    visualizarArquivo(file) {
        const self = this;
        if (file.urldocass) {
          window.open(file.urldocass, "_blank");
        } else {
          self.carregando = true;
          api.obterDocumento(file)
            .then((resp) => {
              this.docFile = URL.createObjectURL(resp);
              this.$refs.modal.openModal();
            })
            .catch(erro => self.erro = (erro || "").mensagem || erro)
            .finally(() => {
                self.carregando = false;
            });
        }
    },
    revokeDocfileUrl() {
      URL.revokeObjectURL(this.docFile);
      this.docFile = "";
    },
    abrirLinkTeste(event, link) {
      if (link == "obterUrlAssinarEmbedded") {
        event.preventDefault();
        const self = this;
        self.carregando = true;
        api
          .obterUrlAssinarEmbedded({codlotdocasi: this.codlotdocasi})
          .then((resp) => {
            window.location.href = resp.assinarEmbedded;
          })
          .catch(erro => {
            self.erro = (erro || "").mensagem || erro;
          })
          .finally(() => (self.carregando = false));
      } else if (link == "obterUrlAcrobatSign") {
        event.preventDefault();
        const self = this;
        self.carregando = true;
        api
          .obterUrlAcrobatSign({codlotdocasi: this.codlotdocasi})
          .then((resp) => {
            window.location.href = resp.assinarEmbedded;
          })
          .catch(erro => {
            self.erro = (erro || "").mensagem || erro;
          })
          .finally(() => (self.carregando = false));
      }
    }
  },
};
</script>
<style>
.example-simple label.btn {
  margin-bottom: 0;
  margin-right: 1rem;
}
</style>