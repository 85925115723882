<template>
  <uspCartao :titulo="titulo">
    <template slot="corpo">
      <uspModal titulo="Editar vínculo" ref="modal">
        <template slot="body">
          <label for="temp" class="control-label"
            >Selecione o vínculo de
            {{ (selecionados[usrEditPos] || {}).nome }}</label
          >
          <select id="temp" class="form-control" v-model="temp" required>
            <option
              v-for="(value, key) in papeis"
              :value="key"
              v-bind:key="key"
            >
              {{ value }}
            </option>
          </select>
          <div class="mt-3" v-if="tipasilotdoc == 'U' && temp == 'A'">
            <label for="temp2" class="control-label">
              Perfil assinante:
            </label>
            <div>
              <span class="scopedtooltip w-100">
                <input id="temp2" class="form-control" v-model="temp2">
                <span class="scopedtooltiptext">
                  Neste campo, você pode informar o vínculo deste assinante neste lote de documento, como, por exemplo:
                  diretor, testemunha, contratante, contratado, partícipe ou qualquer outro nome utilizado no documento.
                </span>
              </span>
            </div>
          </div>
        </template>

        <div slot="footer">
          <button
            type="button"
            class="btn btn-outline-danger"
            @click="$refs.modal.closeModal()"
          >
            Cancelar
          </button>
          <button
            type="button"
            class="btn btn-success"
            data-dismiss="modal"
            @click="confirmar()"
          >
            Confirmar
          </button>
        </div>
      </uspModal>

      <select class="form-control mb-3" v-model="comCodpes" v-if="tipasilotdoc != 'U' && modoEdicao">
        <option :value="true">Participante possui número USP</option>
        <option :value="false">Participante não possui número USP</option>
      </select>
      <div class="pesquisar" v-if="modoEdicao && comCodpes">
        <!-- Formulário apenas de exemplo -->
        <form class="form-row" @submit.prevent="buscar">
          <div class="form-group col-12 col-xl-2">
            <label for="buscaCodpes" class="control-label">Número USP</label>
            <input
              type="text"
              v-model="buscaCodpes"
              class="form-control"
              id="buscaCodpes"
            />
          </div>
          <div class="form-group col-12 col-xl-4">
            <label for="buscaNompes" class="control-label">Nome</label>
            <input
              type="text"
              v-model="buscaNompes"
              class="form-control"
              id="buscaNompes"
            />
          </div>
          <div class="form-group col-12 col-xl-4">
            <label for="buscaCodema" class="control-label">E-mail</label>
            <input
              type="text"
              v-model="buscaCodema"
              class="form-control"
              id="buscaCodema"
            />
          </div>
          <div class="form-group col-12 col-xl-2 d-flex align-items-end">      
            <button type="submit" class="btn btn-primary btn-block" :disabled="buscando">
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
                v-if="buscando"
              ></span>
              Buscar
            </button>
          </div>
        </form>
        <!-- Resultados da pesquisa -->
        <AssinaResultados
          :lista="resultadosBusca"
          v-if="temResultados"
          titulo="Resultados da busca"
        >
          <template slot-scope="conteudo">
            <div class="conteudo">
              <div class="multiplas-linhas">
                <span class="titulo"> {{ conteudo.item.nome }}</span>
                <span class="subtitulo"
                  >Número USP: {{ conteudo.item.codpes }}
                </span>
              </div>
            </div>
            <div class="acoes ml-3" v-if="modoEdicao">
              <select class="form-control" v-model="conteudo.item.tipptpasidoc" required>
                <option
                  v-for="(value, key) in papeis"
                  :value="key"
                  v-bind:key="key"
                >
                  {{ value }}
                </option>
              </select>
            </div>
            <div class="acoes ml-3" v-if="tipasilotdoc == 'U' && conteudo.item.tipptpasidoc == 'A'">
              <span class="scopedtooltip">
                <input
                  type="text"
                  v-model="conteudo.item.prfpcplotdoc"
                  class="form-control"
                  id="prfpcplotdoc"
                  placeholder="Perfil assinante"
                />
                <span class="scopedtooltiptext scopedtooltiptext-w-200">
                  Neste campo, você pode informar o vínculo deste assinante neste lote de documento, como, por exemplo:
                  diretor, testemunha, contratante, contratado, partícipe ou qualquer outro nome utilizado no documento.
                </span>
              </span>
            </div>
            <div class="acoes ml-3">
              <button
                type="button"
                class="btn btn-sm btn-success icone_mesma_linha"
                @click="adicionar(conteudo.item)"
                :disabled="!conteudo.item.tipptpasidoc && modoEdicao"
              >
                <i class="fas fa-sm fa-plus"></i> Adicionar
              </button>
            </div>
          </template>
        </AssinaResultados>
      </div>

      <div v-if="modoEdicao && !comCodpes">
        <form>
          <div class="conteudo row">
            <div class="form-group col-md-12 col-xl-2">
              <label 
                for="numcpfpcpasi"
                class="control-label"
              >
                CPF
              </label>
              <input
                id="numcpfpcpasi"
                v-model="novoParticipante.numcpfpcpasi"
                v-maska
                data-maska="###.###.###-##"
                class="form-control"
                @blur="buscarSemNUSP()"
              >
            </div>
            <div class="form-group col-md-12 col-xl-3">
              <label 
                for="emapcpasi" 
                class="control-label"
              >
                E-mail
              </label>
              <input
                id="emapcpasi"
                v-model="novoParticipante.emapcpasi"
                class="form-control"
              >
            </div>
            
            <div class="form-group col-md-12 col-xl-3">
              <label for="nome" class="control-label">Nome</label>
              <input
                type="text"
                v-model="novoParticipante.nome"
                class="form-control"
                id="nome"
              />
            </div>
            <div class="form-group col-md-12 col-xl-2">
              <label for="tipptpasidoc" class="control-label">Papel</label>
              <select id="tipptpasidoc" v-model="novoParticipante.tipptpasidoc" class="form-control" required>
                <option
                  v-for="(value, key) in papeis"
                  :value="key"
                  v-bind:key="key"
                >
                  {{ value }}
                </option>
              </select>
            </div>
          
          <div class="form-group col-md-12 col-xl-2 d-flex align-items-end">
            <button
              type="button"
              class="btn btn-success btn-block"
              @click="adicionar(novoParticipante)"
            >
              <i class="fas fa-sm fa-plus"></i> Adicionar
            </button>
          </div>
          </div>
        </form>
      </div>

      <!-- Participantes já incluídos -->
      <p v-if="modoEdicao">
        Deve haver pelo menos um participante assinante, para que seja possível iniciar o processo.
      </p>
      <AssinaResultados
        :lista="selecionados"
        titulo="Pessoas vinculadas ao documento"
        destaque
        class="mt-3"
      >
        <template slot-scope="conteudo">
          <div class="conteudo">
            <div class="multiplas-linhas">
              <span class="titulo"> {{ conteudo.item.nome }}</span>
              <span class="subtitulo">
                <span v-if="conteudo.item.numcpfpcpasi">CPF: {{ conteudo.item.numcpfpcpasitxt }} | </span>
                <span v-if="conteudo.item.emapcpasi">E-mail: {{ conteudo.item.emapcpasi }} | </span>
                <span v-if="conteudo.item.codpes">Número USP: {{ conteudo.item.codpes }} | </span>
                <span>
                  Perfil: {{ papeis[conteudo.item.papel] }}
                  <span v-if="conteudo.item.prfpcplotdoc"> ({{ conteudo.item.prfpcplotdoc }}) </span>
                </span>
              </span>
            </div>
          </div>
          <div class="acoes" v-if="sitlotdocasi != 'REJ'">
            <button
              type="button"
              class="btn btn-sm btn-primary"
              @click="editar(conteudo.item)"
              v-if="modoEdicao"
            >
              <i class="fas fa-edit"></i> Editar função
            </button>
            <button
              type="button"
              class="btn btn-sm btn-danger"
              @click="remover(conteudo.item)"
              v-if="modoEdicao"
            >
              <i class="fas fa-sm fa-times"></i> Remover
            </button>

            <button
              type="button"
              class="btn btn-sm btn-outline-primary"
              @click="reenviarEmail(conteudo.item)"
              v-if="!modoEdicao && (conteudo.item.reenviar == 'S') && sitlotdocasi != 'ASS'"
              :disabled="carregando"
            >
              <i class="fas fa-paper-plane"></i> Reenviar email
            </button>
            
            <button
              type="button"
              class="btn btn-sm"
              :class="statusDocPart[conteudo.item.staasn].cor"
              v-if="!modoEdicao && conteudo.item.staasn && sitlotdocasi != 'ASS'"
              disabled
            >
              <i :class="statusDocPart[conteudo.item.staasn].icone"></i> {{ statusDocPart[conteudo.item.staasn].nome }}
            </button>
          </div>
        </template>
      </AssinaResultados>
    </template>
  </uspCartao>
</template>

<script>
import { PortalLogger as Log } from '@/utils/';
import AssinaResultados from '../components/AssinaResultados';
import LoteDocumentoServico from '../dominio/loteDocumento/LoteDocumentoServico.js';

const api = LoteDocumentoServico.build({});

export default {
  name: 'AssinaPessoas',

  components: {
    AssinaResultados
  },

  props: {
    titulo: {
      type: String,
      default: '',
    },
    modoEdicao: {
      type: Boolean,
      default: false,
    },
    codlotdocasi: {
      type: String,
      default: "",
    },
    tipasilotdoc: {
      type: String,
      default: "",
    },
    sitlotdocasi: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      usrEditPos: 0,
      temp: "",
      temp2: "",
      comCodpes: true,
      buscaCodpes: "",
      buscaNompes: "",
      buscaCodema: "",
      buscando: false,
      novoParticipante: {
        emapcpasi: "",
        nome: ""
      },
      resultadosBusca: [],
      selecionados: [],
      prfpcplotdoc: "",
      erro: "",
      carregando: false
    };
  },

  computed: {
    temResultados() {
      return this.resultadosBusca.length > 0;
    },
  },

  watch: {
    selecionados(newValue) {
      Log.info("Atualizacao selecionados!");
      this.$emit(
          "uspPessoaAtualizouLista",
          ((newValue || []).length && newValue.some(elem => elem.tipptpasidoc == "A"))
      );
    },
    buscaCodpes() {
      if (this.buscaCodpes) this.limparCamposBusca("buscaCodpes");
    },
    buscaNompes() {
      if (this.buscaNompes) this.limparCamposBusca("buscaNompes");
    },
    buscaCodema() {
      if (this.buscaCodema) this.limparCamposBusca("buscaCodema");
    },
    erro(newValue) {
      if (newValue) {
        this.$toast.error(`Erro ao buscar participantes! ${this.erro}`);
        this.erro = "";
      }
    },
  },

  created() {
    this.papeis = {...this.$uassConst("papeis")};
    // if (this.tipasilotdoc == "U") {
    //   delete this.papeis.A;
    // }
    this.statusDocPart = {
      0: {
        nome: "",
        cor: "",
        icone: "",
      },
      1: {
        nome: "Pendente",
        cor: "btn-warning",
        icone: "fas fa-hourglass-start",
      },
      2: {
        nome: "Assinado",
        cor: "btn-success",
        icone: "fas fa-signature",
      },
      3: {
        nome: "Autorizado",
        cor: "btn-success",
        icone: "fas fa-check",
      },
      4: {
        nome: "Não autorizado",
        cor: "btn-danger",
        icone: "fas fa-thumbs-down",
      },
    };
  },

  mounted() {
    //this.habilitarBusca = this.modoEdicao;
    this.listarParticipantes();
  },

  methods: {
    limparCamposBusca(menosEste) {
      const self = this;
      ["buscaCodpes", "buscaNompes", "buscaCodema"]
        .filter((elem) => elem != menosEste)
        .forEach((elem) => (self[elem] = ""));
    },

    buscar() {
      const self = this;
      self.resultadosBusca = [];
      self.buscando = true;
      api
        .buscarUsuario({
          codpes: self.buscaCodpes,
          nompes: self.buscaNompes,
          codema: self.buscaCodema,
        })
        .then((resp) => {
          self.resultadosBusca = resp;
          if (resp.length == 0) {
            self.erro = "Usuário não encontrado!";
          }
        })
        .catch(erro => {
          self.erro = (erro || "").mensagem || erro;
        })
        .finally(() => {
          self.buscando = false;
        });
    },

    buscarSemNUSP() {
      const self = this;
      api
        .buscarUsuarioSemNUSP({
          numcpfpcpasi: (self.novoParticipante.numcpfpcpasi || '').replaceAll(/[.-]/g, '')
        })
        .then((resp) => {
          Log.info(resp);
          if (resp.length > 0) {
            Log.info(resp[0]);
            self.novoParticipante.emapcpasi = resp[0].emapcpasi;
            self.novoParticipante.nome = resp[0].nompcpasi;
          }
        });
    },

    adicionar(pessoa) {
      Log.info(pessoa)
      const self = this;
      /* Adiciona o usuário aos selecionados */
      //this.selecionados.push(pessoa);
      this.salvarDadosParticipante({
          codlotdocasi: this.codlotdocasi, //codigo do lote de documentos
          tipptpasidoc: this.modoEdicao ? pessoa.tipptpasidoc : "O", //codigo tipo participante
          tippespcpasi: this.tippespcpasi || "F", //tipo pessoa (fisica ou juridica)
          codpespcpasi: pessoa.codpes, //numero USP quando houver
          codorgpcpasi: this.codorgpcpasi, //codigo organizacao cadastrada em tabela
          numcpfpcpasi: (pessoa.numcpfpcpasi || this.numcpfpcpasi || '').replaceAll(/[.-]/g, ''), //numero CPF
          numcpjpcpasi: this.numcpjpcpasi, //numero CNPJ
          nompcpasi: pessoa.nome, //nome do participante
          emapcpasi: pessoa.emapcpasi || this.emapcpasi, //email do participante
          prfpcplotdoc: pessoa.prfpcplotdoc || this.prfpcplotdoc, //vinculo do assinante
        },
        function() {
          /* Remover o usuário dos resultados busca */
          self.resultadosBusca = self.resultadosBusca.filter(
            (elem) => elem.codpes != pessoa.codpes
          );

          /* Limpar novoParticipante */
          self.novoParticipante = {};
        }
      );
    },

    salvarDadosParticipante(pessoa, callBackFunc) {
      const self = this;
      self.carregando = true;
      api
        .registrarParticipante(pessoa)
        .then(() => {
          self.listarParticipantes();
          if (typeof callBackFunc === 'function') callBackFunc();
        })
        .catch((erro) => (self.erro = (erro || "").mensagem || erro))
        .finally(() => {
          self.carregando = false;
        });
    },

    listarParticipantes() {
      const self = this;
      self.carregando = true;
      api
        .listarParticipante({
          codlotdocasi: self.codlotdocasi,
        })
        .then((resp) => {
          self.selecionados = resp;
        })
        .catch(erro => {
          self.erro = (erro || "").mensagem || erro;
        })
        .finally(() => (self.carregando = false));
    },

    remover(pessoa) {
      const self = this;
      self.carregando = true;
      api
        .removerParticipante(pessoa)
        .then(() => {
          self.listarParticipantes();
        })
        .catch((erro) => (self.erro = (erro || "").mensagem || erro))
        .finally(() => (self.carregando = false));
    },

    editar(pessoa) {
      this.usrEditPos = this.selecionados.findIndex(
        (elem) => elem.codpcpasidoc == pessoa.codpcpasidoc
      );
      this.temp = pessoa.tipptpasidoc;
      this.temp2 = pessoa.prfpcplotdoc;
      this.$refs.modal.openModal();
    },

    confirmar() {
      const participante = this.selecionados[this.usrEditPos];
      participante.tipptpasidoc = this.temp;
      participante.prfpcplotdoc = this.temp2;
      this.salvarDadosParticipante(participante);
      this.$refs.modal.closeModal();
    },

    reenviarEmail(pessoa) {
      const self = this;
      self.carregando = true;
      api
        .reenviarEmail(pessoa)
        .then(() => self.$toast.success("Email enviado com sucesso!"))
        .catch(erro => self.$toast.error(erro?.mensagem || erro))
        .finally(() => (self.carregando = false));
    },
  },
};
</script>

<style scoped lang="scss">
  @import "./src/componentes/estilos/temas.scss";
  
  .scopedtooltip {
    position: relative;
    display: inline-block;
  }

  .scopedtooltip .scopedtooltiptext {
    visibility: hidden;
    width: 100%;
    background-color: black;
    color: #fff;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: calc(100% + .5em);
    left: 50%;
    margin-left: -50%;
    padding: .5em;
  }

  .scopedtooltip .scopedtooltiptext-w-200 {
    width: 200%;
    left: 50%;
    margin-left: -100%;
  }

  .scopedtooltip:hover .scopedtooltiptext {
    visibility: visible;
  }

  .scopedtooltip .scopedtooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -.5em;
    border-width: .5em;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

  button.icone_mesma_linha {
    width: max-content;
  }

  @each $tema in $temas {
  $elemento: map-get($tema, "elemento");
  #{$elemento} {
    .scopedtooltip {
      .scopedtooltiptext {
        background-color: map-get($tema, "usp-cabecalho-cor-de-fundo");
        color: map-get($tema, "usp-cabecalho-cor-do-texto");

        &::after {
          border-color: map-get($tema, "usp-cabecalho-cor-de-fundo") transparent transparent transparent;
        }
      }
    }
  }
}
</style>